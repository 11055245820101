import React from 'react';
import { ThemeProvider } from './components/ThemeContext'; // Asegúrate de importar ThemeProvider correctamente
import ThemeSwitcher from './components/ThemeSwitcher'; // Importa el botón de cambio de tema
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Inicio from './components/Inicio/Inicio';
import AcercaDe from './components/AcercaDe/AcercaDe';
import Servicios from './components/Servicios/Servicios';
import Proyectos from './components/Proyectos/Proyectos';
import Contacto from './components/Contacto/Contacto';
import WhatsAppChat from './components/WhatsApp/WhatsAppChat';

function App() {
  return (
    <ThemeProvider>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Inicio />} />
          <Route path="/acerca-de" element={<AcercaDe />} />
          <Route path="/servicios" element={<Servicios />} />
          <Route path="/proyectos" element={<Proyectos />} />
          <Route path="/contacto" element={<Contacto />} />
        </Routes>
        <Footer />
        <WhatsAppChat />
        <ThemeSwitcher />
      </Router>
    </ThemeProvider>
  );
}

export default App;
