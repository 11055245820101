import React, { useState, useEffect } from 'react';
import '../../css/Whatsapp.css'; // Asegúrate de que la ruta sea correcta
import logo from '../../assets/whatsapp1.png'; // Asegúrate de que la ruta sea correcta

const WhatsAppChat = () => {
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [messageIndex, setMessageIndex] = useState(0); // Inicia en 0 para mostrar el mensaje de bienvenida de inmediato
    const [userInput, setUserInput] = useState({
        service: '',
        name: '',
        email: ''
    });
    const [showForm, setShowForm] = useState(false);

    const services = [
        "Diagnóstico y Consultoría",
        "Organización Documental",
        "Digitalización",
        "Instrumentos Archivísticos",
        "Microfilmación",
        "Almacenamiento y Custodia"
    ];

    const additionalMessages = [
        "Hola, somos EGS Soluciones Integrales. ¿Quieres chatear con nosotros?",
        "Somos la mejor opción para ti. Contáctanos.",
        "Estamos aquí para ayudarte. ¡Envíanos un mensaje!",
        "¿Necesitas ayuda? Estamos disponibles para ti."
    ];

    useEffect(() => {
        let timer;
        if (!isChatOpen && !showForm) {
            if (messageIndex < additionalMessages.length) {
                timer = setTimeout(() => {
                    setMessageIndex(prevIndex => prevIndex + 1);
                }, 5000); // 5 segundos para cada mensaje
            } 
            // Este control estaba causando confusión en la lógica original. Se ha ajustado para simplificar.
            if (messageIndex === additionalMessages.length) {
                // Espera 10 segundos antes de reiniciar los mensajes
                timer = setTimeout(() => {
                    setMessageIndex(0);
                }, 10000); // 10 segundos de pausa antes de reiniciar
            }
        }

        return () => clearTimeout(timer);
    }, [messageIndex, additionalMessages.length, isChatOpen, showForm]);

    const handleServiceSelect = (service) => {
        setUserInput({ ...userInput, service });
        setShowForm(true); // Muestra el formulario directamente
        setIsChatOpen(false); // Asegura que el chat se cierre al seleccionar un servicio
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserInput({ ...userInput, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Componer el mensaje y abrir WhatsApp
        const message = encodeURIComponent(`Hola, mi nombre es ${userInput.name}, mi correo es ${userInput.email}, y estoy interesado en el servicio de ${userInput.service}.`);
        window.open(`https://wa.me/+573106898790?text=${message}`, '_blank');
        // Reiniciar el estado para volver al inicio del ciclo de mensajes
        setShowForm(false);
        setMessageIndex(0); // Reinicia los mensajes desde el inicio
    };

    const toggleChat = () => {
        setIsChatOpen(!isChatOpen);
        // Si el usuario quiere cerrar el chat de servicios, permite reiniciar los mensajes inmediatamente
        if (isChatOpen) {
            setShowForm(false); // Oculta el formulario si se está mostrando
            setMessageIndex(0); // Reinicia los mensajes
        }
    };

    return (
        <div className="whatsapp-container">
            <div className="chat-icon" onClick={toggleChat}>
                <img src={logo} alt="Chat con nosotros" />
            </div>
            {!isChatOpen && !showForm && messageIndex < additionalMessages.length && (
                <div className="chat-box welcome-message">
                    {additionalMessages[messageIndex]}
                </div>
            )}
            {isChatOpen && (
                <div className="chat-box services-message">
                    <div className="services-title">¿En qué servicio estás interesado?</div>
                    {services.map((service, index) => (
                        <div key={index} className="chat-option" onClick={() => handleServiceSelect(service)}>
                            {service}
                        </div>
                    ))}
                </div>
            )}
            {showForm && (
                <form onSubmit={handleSubmit} className="chat-form" style={{ width: '300px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <input
                        type="text"
                        name="name"
                        placeholder="Tu nombre"
                        value={userInput.name}
                        onChange={handleChange}
                        required
                        style={{ padding: '10px' }}
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Tu correo electrónico"
                        value={userInput.email}
                        onChange={handleChange}
                        required
                        style={{ padding: '10px' }}
                    />
                    <button type="submit" style={{ padding: '10px' }}>Enviar</button>
                </form>
            )}
        </div>
    );
};

export default WhatsAppChat;
