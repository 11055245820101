import React, {useEffect, useState } from 'react';
import '../../css/Servicios.css';
// Importa imágenes
import imagen1 from '../../assets/imagen1.png';
import imagenModal1 from '../../assets/imagenModal1.png';
import imagen2 from '../../assets/imagen2.png';
import imagen3 from '../../assets/imagen3.png';
import imagen4 from '../../assets/imagen4.png';
import imagen5 from '../../assets/imagen5.png';
import imagen6 from '../../assets/imagen6.png';
import imagen7 from '../../assets/imagen7.png';
// Importa videos
import video2 from '../../assets/video2.mp4';
import video1 from '../../assets/video1.mp4';
import video3 from '../../assets/video3.mp4';
import video4 from '../../assets/video4.mp4';
import video5 from '../../assets/video5.mp4';
import video6 from '../../assets/video6.mp4';
import video7 from '../../assets/video7.mp4';


const servicios = [
    {
        id: 1,
        titulo: "Diagnostico y Consultoria",
        imagen: imagen1,
        video: video1,
        imagenModal: imagenModal1,
        descripcion: "EGS soluciones integrales brinda el servicio de diagnostico con base en el levantamiento de la informacion y el analisis integral",
        descripcionLarga: "Funcionamiento de la gestión documental de cada una de las dependencias, revisando su estructura orgánico funcional y operativa, identificando a partir de ello la situación real de sus archivos, así como la proyección de crecimiento con miras en la identificación y planificación de los recursos requeridos para su organización. Posterior al diagnóstico brindamos todas las opciones disponibles para que su empresa pueda implementar las mejores prácticas de gestión documental, y de esa manera pueda organizar y acceder a su información de manera rápida y sencilla.",
        alcance: [
          "Visita a las instalaciones de su empresa.",
          "Revisión de los archivos y documentación física y digital.",
          "Análisis y diagnóstico de la situación actual de la empresa.",
          "Definición del modelo de gestión ajustado a las necesidades de su empresa.",
          "Entrega de informe final."
        ],
      },
      
  {
    id: 2,
    titulo: "Organizacion Documental",
    imagen: imagen2,
    video: video2, // Asigna el video importado aquí
    descripcion: "Con este servicio usted podra conocer de manera exacta con que documentación cuenta su compañia tanto a nivel historico como actual",
    descripcionLarga: "La relevancia de la misma para de esa manera tomar decisiones.Este procedimiento se realiza de acuerdo con los más altos estándares de calidad y dando cumplen-do a lo ordenado por el Archivo General de la Nación.",
    alcance: [
      "Aplicar tablas de TRD y TVD a los expedientes.",
      "Organización de la documentación cronológicamente o de acuerdo a la necesidad del cliente.",
      "Eliminación de la duplicidad de documentos.",
      "Preparación, retiro de ganchos, limpieza general de los documentos para garantizar la calidad del expediente.",
      "Foliación de la documentación respetando las unidades de conservación según el caso que aplique.",
      "Levantamiento de inventario adoptando el formato único de inventario documental (FUID).",
      "Control de calidad de asuntos y series.",
      "Marcar y rotular de tal forma que permita su ubicación (caja-carpeta).",
      "Entrega de informe final."
    ],
    // icono: opcional, si también quieres tener iconos
  },
  {
    id: 3,
    titulo: "Digitalizacion",
    imagen: imagen3,
    video: video3, // Asigna el video importado aquí
    descripcion: "Disponer de la información de forma mucho más rápida, acceder a ella las 24 horas del día y los 7 días de la semana, disminuyendo riesgos potenciales de pérdida y deterioro",
    descripcionLarga: "(La documentación a digitalizar debe estar organizada de acuerdo a las necesidades).l servicio de digitalización lo prestamos en nuestras instalaciones o en sus instalaciones si así lo prefiere.",
    alcance: [
      "Verificación del inventario vs información física.",
      "Captura de la imagen digital del documento garantizando que sea copia fiel e íntegra del documento.",
      "Control de calidad para validar que efectivamente cumple con los parámetros de nitidez de imagen, entre otros aspectos de calidad.",
      "La imagen obtenida de la digitalización es en formato PDF la cual se entrega al cliente en un me-dio magnético.",
      "Entrega de una base de datos en Excel con los campos requeridos para la descripción y búsqueda de documento"
    ],
    // icono: opcional, si también quieres tener iconos
  },
  {
    id: 4,
    titulo: "Instrumentos Archivisticos",
    imagen: imagen4,
    video: video4, // Asigna el video importado aquí
    descripcion: "Asesoramos y desarrollamos Instrumentos Archivísticos con el objetivo de apoyar el adecuado desarrollo e implementación de la gestión documental y la función archivística.",
    descripcionLarga: "Aplicar los procesos y procedimientos del cliente y siguiendo los lineamientos dados por el Archivo General de la Nación.",
    alcance: [
      "Cuadro de Clasificación Documental – CCD",
      "Tablas de Retención Documental – TRD",
      "Tablas de Valoración Documental – TVD",
      "Programa de Gestión Documental – PGD",
      "Formato Único de Inventario Documental – FUID",
      "Modelo de Requisitos para la Implementación de un Sistema de Gestión de Documentos Electrónicos de Archivo",
      "Banco Terminológico de tipos, series y subseries documentales – BANTER",
      "Tablas de Control de Acceso – TCA"
    ],
    // icono: opcional, si también quieres tener iconos
  },
  {
    id: 5,
    titulo: "Microfilmacion",
    imagen: imagen5,
    video: video5, 
    descripcion: "Técnica fotográfica de registrar en películas de sales de plata, que permite tener una reproducción exacta de un documento sobre un soporte fotográfico normalizado, para preservación de la información a largo plazo.",
    descripcionLarga: "EGS Soluciones Integrales ofrece este servicio para empresas con el fin de generar ahorro de espacio y preservación a largo plazo de la documentación producida, ya que con esta técnica se puede ahorrar más del 98%, no sólo en papeles, sino en elementos de archivo como armarios, estanterías, etc.Además, hace más sencillo el acceso, localización y consulta de la información; protegiendo la información de riesgos como robo, incendio, daño por humedad, insectos, roedores o clonación, puesto que los archivos en microfilm son inalterables, y al ser menor su volumen es más sencillo y económico asegurar la información; además el material es mucho más resistente que el papel y puede llegar a durar hasta 400 años. ",
    alcance: [
      "Rollos máster, se entregan en cajas individuales, debidamente identificadas y los carretes marcados.",
      "Prueba de hiposulfito residual cada 50 rollos.",
      "Entrega de Base de datos en archivo plano formato Excel, que contiene los índices de microfilmación con el contenido de cada rollo facilitando el acceso, localización y consulta de la información.",
      "Entrega de informe de control de calidad para cada rollo de microfilmación.",

    ],
    // icono: opcional, si también quieres tener iconos
  },
  {
    id: 6,
    titulo: "Almacenamiento y Custodia",
    imagen: imagen6,
    video: video6, 
    descripcion: "EGS Soluciones Integrales, pone a su disposición toda su infraestructura física y tecnológica, acompañada de los más altos estándares de calidad, para la administración, custodia de su documentación y servicio de consultas de archivos digitales y físicos.",
    descripcionLarga: "Su cumple con el correspondiente transporte cumpliendo con los protocolos se seguridad. (Cumplimos con los requisitos previstos en los Acuerdos 049 de 2000 y 008 de 2014 del AGN.)",
    alcance: [
      "Estantería específica para carga pesada, alta resistencia, solidez estructural, recubrimientos y aislamiento.",
      "Circuito cerrado de televisión, cámaras externas e internas.",
      "Sistema de seguridad con alarma electrónica integrada.",
      "Servicio de vigilancia las 24 horas",
      "Control de hongos, roedores y voladores, y fumigación regular.",
      "Iluminación y control de humedad de acuerdo con lo establecido por el AGN para la conservación de documentos.",

    ],
    // icono: opcional, si también quieres tener iconos
  },
  {
    id: 7,
    titulo: "Investigacion De Mercados",
    imagen: imagen7,
    video: video7, 
    descripcion: "Diferentes tipos de investigaciones (cualitativos, etnográficos, cuantitativos, evaluación de impacto)",
    descripcionLarga: "Expertos en la investigación de mercados, en los que basamos la experiencia en diferentes etapas (Cualitativas, etnográficas, cuantitativas, evaluaciones de impacto)",
    alcance: [
      "Entrevistas a profundidad.",
      "Talleres de reconstrucción colectiva.",
    ],
    // icono: opcional, si también quieres tener iconos
  },
];

function Servicios() {
    const [modalVisible, setModalVisible] = useState(false);
    const [servicioActivo, setServicioActivo] = useState({});
  
    const abrirModal = (servicio) => {
      setServicioActivo(servicio);
      setModalVisible(true);
    };
  
    const cerrarModal = () => {
      setModalVisible(false);
    };
    useEffect(() => {
        const manejarTeclaEsc = (evento) => {
          if (evento.key === 'Escape') {
            cerrarModal();
          }
        };
    
        if (modalVisible) {
          window.addEventListener('keydown', manejarTeclaEsc);
        }
    
        return () => {
          window.removeEventListener('keydown', manejarTeclaEsc);
        };
      }, [modalVisible]); // Asegúrate de incluir modalVisible en el array de dependencias para reaccionar a su cambio
    
  
      return (
        <div className="servicios">
          <h1>SERVICIOS DISPONIBLES</h1>
          <div className="servicios-grid">
            {servicios.map((servicio) => (
              <div key={servicio.id} className="servicio">
                <img src={servicio.imagen} alt={servicio.titulo} />
                <h2>{servicio.titulo}</h2>
                <p>{servicio.descripcion}</p>
                <button onClick={() => abrirModal(servicio)}>Descubre más</button>
              </div>
            ))}
          </div>
          {modalVisible && (
            <div className="modal" onClick={cerrarModal}>
              <div className="modal-content" onClick={e => e.stopPropagation()}>
                <span className="close-button" onClick={cerrarModal}>&times;</span>
                <h2>{servicioActivo.titulo}</h2>
                {servicioActivo.video ? (
                  <video
                    autoPlay // Añade auto reproducción
                    playsInline
                    loop     // Añade bucle
                    muted    // Añade silencio
                    style={{ maxWidth: '100%', maxHeight: '300px' }} // Elimina 'controls' para ocultar los controles
                  >
                    <source src={servicioActivo.video} type="video/mp4" />
                    Tu navegador no soporta videos HTML5.
                  </video>
                ) : (
                  <img src={servicioActivo.imagenModal || servicioActivo.imagen} alt="Detalle" style={{ maxWidth: '100%', maxHeight: '300px' }} />
                )}
                <p>{servicioActivo.descripcionLarga}</p>
                <h3>Alcance</h3>
                {servicioActivo.alcance && (
                  <ul>
                    {servicioActivo.alcance.map((punto, index) => (
                      <li key={index}>{punto}</li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          )}
        </div>
      );
      
  }

export default Servicios;
