import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component'; // Importa el componente LazyLoadImage
import 'react-lazy-load-image-component/src/effects/blur.css'; // Importa el CSS para el efecto de desenfoque

// Importa tus estilos y activos
import '../../css/Proyectos.css';
import videoDemo from '../../assets/proyectos/video2.mp4';
import videoDemo2 from '../../assets/proyectos/video1.mp4';

// Importa las imágenes para la galería
import imagen1 from '../../assets/proyectos/1.jpg';
import imagen2 from '../../assets/proyectos/2.jpg';
import imagen3 from '../../assets/proyectos/3.jpg';
import imagen4 from '../../assets/proyectos/4.jpg';
import imagen5 from '../../assets/proyectos/5.jpg';
import imagen6 from '../../assets/proyectos/6.jpg';
import imagen9 from '../../assets/proyectos/9.jpg';
import imagen10 from '../../assets/proyectos/10.jpg';

function Proyectos() {
    return (
        <div className="proyectos-container">
            <h1>PROYECTOS DESTACADOS</h1>
            <div className="contenido-container">
                <div className="proyectos-galeria-container">
                    {/* Utiliza LazyLoadImage para cada imagen */}
                    <LazyLoadImage src={imagen1} alt="Proyecto 1" effect="blur" />
                    <LazyLoadImage src={imagen2} alt="Proyecto 2" effect="blur" />
                    <LazyLoadImage src={imagen3} alt="Proyecto 3" effect="blur" />
                    <LazyLoadImage src={imagen4} alt="Proyecto 4" effect="blur" />
                    <LazyLoadImage src={imagen5} alt="Proyecto 5" effect="blur" />
                    <LazyLoadImage src={imagen6} alt="Proyecto 6" effect="blur" />
                    <LazyLoadImage src={imagen9} alt="Proyecto 9" effect="blur" />
                    <LazyLoadImage src={imagen10} alt="Proyecto 10" effect="blur" />
                </div>
                <div className="videos-wrapper">
                    <div className="proyectos-video-container">
                        <video autoPlay loop muted playsInline className="video-2">
                            <source src={videoDemo} type="video/mp4" />
                            Tu navegador no soporta videos.
                        </video>
                    </div>
                    <div className="proyectos-video-container">
                        {/* Aplica la clase "video-2" al segundo video */}
                        <video autoPlay loop controls playsInline className="video-1">
                            <source src={videoDemo2} type="video/mp4" />
                            Tu navegador no soporta videos.
                        </video>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Proyectos;
