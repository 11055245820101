import React, { useState, useEffect } from 'react';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa';
import vistaImage from '../../assets/vistas.png';
import anosImage from '../../assets/años.png';
import nacionalImage from '../../assets/nacional.png';
import inicioVideo from '../../assets/inicio1.mp4'; // Primer video
import intro2Video from '../../assets/inicio2.mp4'; // Segundo video, asegúrate de que la ruta es correcta
import '../../css/inicio.css';

function Inicio() {
    const [current, setCurrent] = useState(0);
    const [pageVisits, setPageVisits] = useState(() => {
        const savedVisits = localStorage.getItem('pageVisits');
        return savedVisits ? parseInt(savedVisits, 10) : 0;
    });

    const items = [
        { type: 'video', src: inicioVideo },
        { type: 'video', src: intro2Video },
    ];

    useEffect(() => {
        setPageVisits(visits => {
            const newVisits = visits + 1;
            localStorage.setItem('pageVisits', newVisits.toString());
            return newVisits;
        });
    }, []);

    useEffect(() => {
        const videoElement = document.querySelector('.img-horizontal-display');
        if (videoElement) {
            const updateItemOnVideoEnd = () => setCurrent(current => (current + 1) % items.length);
            videoElement.addEventListener('ended', updateItemOnVideoEnd);
            return () => videoElement.removeEventListener('ended', updateItemOnVideoEnd);
        }
    }, [current, items.length]);

    const nextItem = () => {
        setCurrent(current => (current + 1) % items.length);
    };

    const prevItem = () => {
        setCurrent(current => (current - 1 + items.length) % items.length);
    };

    const renderItem = (item) => {
        return (
            <video autoPlay muted playsInline className="img-horizontal-display" key={item.src}>
                <source src={item.src} type="video/mp4" />
                Tu navegador no soporta videos HTML5.
            </video>
        );
    };

    return (
        <div className="container">
            <h1 className="main-title">BIENVENIDO A EGS</h1>
            <h1 className="sub-title">SOLUCIONES INTEGRALES</h1>
            <p className="quality-standards">Estándares de calidad</p>
            <div className="horizontal-display">
                <div className="horizontal-item">
                    <img src={vistaImage} alt="Visitas" className="horizontal-icon" />
                    <div className="horizontal-info">Visitas: {pageVisits}</div>
                </div>
                <div className="horizontal-item">
                    <img src={anosImage} alt="Años de experiencia" className="horizontal-icon" />
                    <div className="horizontal-info">Más de 5 años de experiencia</div>
                </div>
                <div className="horizontal-item">
                    <img src={nacionalImage} alt="Atención Nacional" className="horizontal-icon" />
                    <div className="horizontal-info">Atención a nivel nacional</div>
                </div>
            </div>
            <div className="img-horizontal">
                <FaArrowAltCircleLeft className="left-arrow" onClick={prevItem} />
                {renderItem(items[current])}
                <FaArrowAltCircleRight className="right-arrow" onClick={nextItem} />
            </div>
        </div>
    );
}

export default Inicio;
