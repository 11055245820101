import React from 'react';
import { useTheme } from './ThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';

const ThemeSwitcher = () => {
  const { theme, toggleTheme } = useTheme();

  // Determina el icono basado en el tema actual
  const icon = theme === 'dark' ? faSun : faMoon;

  return (
    <button onClick={toggleTheme} style={{ 
      position: 'fixed', 
      top: '20px', 
      left: '20px', 
      zIndex: 1000, 
      background: 'none', 
      border: 'none',
      cursor: 'pointer',
      fontSize: '24px' // Puedes ajustar este valor según necesites
    }}>
      <FontAwesomeIcon icon={icon} size="1x" /> {/* Ajusta el tamaño aquí */}
    </button>
  );
};

export default ThemeSwitcher;
