import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { FaMapMarkerAlt, FaPhone, FaEnvelope, FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import '../../css/Contacto.css';


emailjs.init("xUfSC6CBfaR_tvngV");

function Contacto() {
    const [form, setForm] = useState({
        nombre: '',
        apellido: '',
        email: '',
        telefono: '',
        servicio: '',
        otroServicio: ''
    });

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [mostrarOtroServicio, setMostrarOtroServicio] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value
        });
        if (name === 'servicio') {
            setMostrarOtroServicio(value === 'Otros');
        }
    };

    const sendEmail = (e) => {
        e.preventDefault();
    
        // Comprueba si el campo 'servicio' es 'Otros' y 'otroServicio' está vacío
        const isOtherServiceSelected = form.servicio === 'Otros' && !form.otroServicio.trim();
    
        // Comprueba si algún campo obligatorio está vacío
        const isAnyFieldEmpty = !form.nombre.trim() || !form.apellido.trim() || !form.email.trim() || !form.telefono.trim() || !form.servicio.trim() || isOtherServiceSelected;
    
        if (isAnyFieldEmpty) {
            // Aquí podrías mostrar un mensaje de error o manejar la validación como prefieras
            console.log('Por favor, completa todos los campos.');
            return; // Detiene la ejecución si algún campo está vacío
        }
    
        // Si todos los campos están llenos, procede a enviar el formulario
            emailjs.sendForm('service_gd9u7gp', 'template_66yy1f7', e.target)
            .then((result) => {
                console.log(result.text);
                setShowSuccessMessage(true);
                setTimeout(() => setShowSuccessMessage(false), 5000);
                // Limpia el formulario después de enviar
                setForm({
                    nombre: '',
                    apellido: '',
                    email: '',
                    telefono: '',
                    servicio: '',
                    otroServicio: ''
                });
            }, (error) => {
                console.log(error.text);
            });
    
    };
    

    return (
        <div className="contacto-container">
            <h1>CONTACTO</h1>
            <p>¡Síguenos en nuestras redes sociales!, también registra tus datos y estaremos en contacto para toda la ayuda documental que necesitas.</p>
            <div className="info-contacto">
                <p><FaMapMarkerAlt /> Dirección: Carrera 69q #75-45 Bogotá</p>
                <p><FaPhone /> Móvil: 3106898790</p>
                <p><FaEnvelope /> Email: Recursoshumanos@egssas.com</p>
                <p>
                    <a href="https://www.facebook.com/people/EGS-SAS/100068304986555/" target="_blank" rel="noopener noreferrer" className="social-icon"><FaFacebook /></a>
                    <a href="https://twitter.com/Egs_sas" target="_blank" rel="noopener noreferrer" className="social-icon"><FaTwitter /></a>
                    <a href="https://www.instagram.com/egs_sas/" target="_blank" rel="noopener noreferrer" className="social-icon"><FaInstagram /></a>
                </p>

                    </div>
            {showSuccessMessage && (
                <div className="success-message">
                    Tu mensaje ha sido enviado con éxito. ¡Gracias por contactarnos!
                </div>
            )}
            <form className="formulario-contacto" onSubmit={sendEmail}>
                <input type="text" name="nombre" placeholder="Nombre" value={form.nombre} onChange={handleChange} required />
                <input type="text" name="apellido" placeholder="Apellido" value={form.apellido} onChange={handleChange} required />
                <input type="email" name="email" placeholder="Email" value={form.email} onChange={handleChange} required />
                <input type="tel" name="telefono" placeholder="Teléfono" value={form.telefono} onChange={handleChange} required />
                <select name="servicio" value={form.servicio} onChange={handleChange} required className="input-style">
                    <option value="">Seleccione un servicio</option>
                    <option value="Diagnóstico y Consultoría">Diagnóstico y Consultoría</option>
                    <option value="Organización Documental">Organización Documental</option>
                    <option value="Digitalización">Digitalización</option>
                    <option value="Instrumentos Archivísticos">Instrumentos Archivísticos</option>
                    <option value="Microfilmación">Microfilmación</option>
                    <option value="Almacenamiento y Custodia">Almacenamiento y Custodia</option>
                    <option value="Otros">Otros</option>
                </select>
                {mostrarOtroServicio && (
                    <input type="text" name="otroServicio" placeholder="Especifique el servicio" value={form.otroServicio} onChange={handleChange} required className="input-style" />
                )}
                <button type="submit" className="submit-btn">Enviar</button>
            </form>
        </div>
    );
}

export default Contacto;
