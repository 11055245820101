// Footer.js

import React from 'react';
import FacebookIcon from '../assets/facebook.png';
import InstagramIcon from '../assets/instagram.png';
import TwitterIcon from '../assets/twitter.png';
import TikTokIcon from '../assets/tiktok.png';
import '../css/inicio.css'; // Asegúrate de que los estilos del footer están definidos aquí o importa un CSS específico para el footer

function Footer() {
    return (
        <div className="footer">
            <div className="social-icons">
                <a href="https://www.facebook.com/people/EGS-SAS/100068304986555/" target="_blank" rel="noopener noreferrer"><img src={FacebookIcon} alt="Facebook" /></a>
                <a href="https://www.instagram.com/egs_sas/" target="_blank" rel="noopener noreferrer"><img src={InstagramIcon} alt="Instagram" /></a>
                <a href="https://twitter.com/Egs_sas" target="_blank" rel="noopener noreferrer"><img src={TwitterIcon} alt="Twitter" /></a>
                <a href="https://www.tiktok.com/@mercadeoegssoluci" target="_blank" rel="noopener noreferrer"><img src={TikTokIcon} alt="TikTok" /></a>
            </div>
            ©2024 EGS Soluciones Integrales,Creado Por Azas-Developer
        </div>
    );
}

export default Footer;
