import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Header.css';
import logo from '../assets/logo.png'; // Asegúrate de ajustar la ruta relativa según sea necesario

function Header() {
    return (
      <header>
        <div className="header-container">
          <div className="logo-container">
            <img src={logo} alt="Logo de la Empresa" />
          </div>
          <nav>
            <ul className="nav-links">
              <li><Link to="/">Inicio</Link></li>
              <li><Link to="/acerca-de">Acerca De</Link></li>
              <li><Link to="/servicios">Servicios</Link></li>
              <li><Link to="/proyectos">Proyectos</Link></li>
              <li><Link to="/contacto">Contacto</Link></li>
            </ul>
          </nav>
        </div>
      </header>
    );
  }
  
  export default Header;