import React from 'react';
import '../../css/AcercaDe.css'; // Asegúrate de que los estilos estén definidos
import videoAcercaDe from '../../assets/Stand.mp4'; // Asegúrate de que la ruta sea correcta para tu video

function AcercaDe() {
    return (
        <div className="acerca-de-container">
            <h1>SOBRE NOSOTROS</h1>
            
            <div className="boxes-container">
                <div className="box" id="mision">
                    <h3>MISIÓN</h3>
                    <p>Brindar soluciones integrales con metodologías apropiadas para lograr satisfacer las necesidades de nuestros clientes a través de la planeación, construcción y desarrollo de proyectos hechos a la medida, que generen bienestar y valor agregado a todos los actores involucrados en el proceso.</p>
                </div>
                <div className="box" id="nosotros">
                    <h3>NOSOTROS</h3>
                    <p>EGS SOLUCIONES INTEGRALES SAS es una firma consultora, especialista en gestión documental e investigación de mercados, dirigida al sector estatal y privado. Vinculando diferentes áreas profesionales y técnicas, adaptándose a las necesidades de los clientes con la mejor tecnología.</p>
                </div>
                <div className="box" id="vision">
                    <h3>VISIÓN</h3>
                    <p>Para el año 2026 duplicar la operación y la oferta de servicios con los mejores estándares de calidad, garantizando ser una de las principales firmas consultoras generadoras de empleo a nivel nacional.</p>
                </div>
            </div>
            
            <div className="video-container video-container-grande">
                <video className="video-grande" autoPlay  playsInline loop controls={false}>
                    <source src={videoAcercaDe} type="video/mp4" />
                </video>
            </div>
            <h1>VALORES EGS</h1>
            <div className="boxes-container">
                <div className="box" id="mision">
                    <h3>CUMPLIMIENTO</h3>
                    <p>Cumplir con las obligaciones, responsabilidades y promesas de carácter laboral y entregar los resultados a los clientes en el momento requerido.</p>
                </div>
                <div className="box" id="vision">
                    <h3>PERSEVERANCIA</h3>
                    <p>Tener firmeza, constancia y persistencia en el actuar para alcanzar los objetivos. No dejarnos vencer por los obstáculos en el cumplimiento de nuestras metas.</p>
                </div>
                <div className="box" id="nosotros">
                    <h3>RESPETO</h3>
                    <p>Tener la decisión firme y responsable de no transgredir los derechos de nuestros semejantes. Actuar, buscando conservar la armonía del ser, de los grupos de personas y del ambiente laboral.</p>
                </div>
                <div className="box" id="nosotros">
                    <h3>HONESTIDAD</h3>
                    <p>Decir siempre la verdad, para sentirnos íntegros y para generar confianza y credibilidad ante los demás.</p>
                </div>
            </div>
        </div>
    );
}

export default AcercaDe;
